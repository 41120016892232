<template>
  <product title="Amazfit 智能运动手表 2S"
    :swiper-data="swiperData"
    :slogan="slogan"
    price="1499"
    :colors="colors"
    :relatived-products="relativedProducts"
    :detail-images="detailImages"
    :param-images="paramImages"
    buy-link="https://j.youzan.com/m6O1Kh"
  ></product>
</template>

<script>
import { CDN_URL, RELATIVED_PRODUCTS } from '@/config'
import Product from '../Product'

export default {
  name: 'stratos-plus',
  components: {
    Product
  },
  data () {
    return {
      slogan: '2.5D 蓝宝石表镜 | 陶瓷表圈 <br/>配送皮质商务表带及亲肤运动氟橡胶表带 | 50 米防水 | 铁人三项<br>VO2max | 5 天续航 | 支付宝离线支付 | 蓝牙音乐播放',
      colors: [
        '黑色'
      ],
      swiperData: [
        [
          CDN_URL + '/images/product/stratos+/swiper/1.png',
          CDN_URL + '/images/product/stratos+/swiper/2.png',
          CDN_URL + '/images/product/stratos+/swiper/3.png',
          CDN_URL + '/images/product/stratos+/swiper/4.png'
        ]
      ],
      relativedProducts: RELATIVED_PRODUCTS,
      detailImages: [
        CDN_URL + '/images/product/stratos+/detail/01w.jpg',
        CDN_URL + '/images/product/stratos+/detail/02.jpg',
        CDN_URL + '/images/product/stratos+/detail/03.jpg',
        CDN_URL + '/images/product/stratos+/detail/04.jpg',
        CDN_URL + '/images/product/stratos+/detail/05.jpg',
        CDN_URL + '/images/product/stratos+/detail/06.jpg',
        CDN_URL + '/images/product/stratos+/detail/07.jpg',
        CDN_URL + '/images/product/stratos+/detail/09.jpg',
        CDN_URL + '/images/common/zepp-white.jpg',
        CDN_URL + '/images/product/stratos+/detail/11.jpg'
      ],
      paramImages: [
        CDN_URL + '/images/product/stratos+/detail/12.jpg'
      ]
    }
  },
  methods: {

  }
}
</script>

<style>
</style>
